//THis is routes/AppRoutes.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ComparePage from '../components/ComparePage';
import SearchPage from '../components/SearchPage';
import SurfboardDetailsPage from '../components/SurfboardDetails';
import GuidedSearch from '../components/GuidedSearch'; // Import correctly

const AppRoutes = () => {
  return (    
      <Routes>        
        <Route path="/compare" element={<ComparePage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/search/:brand" element={<SearchPage />} />
        <Route path="/surfboards/:surfboardId" element={<SurfboardDetailsPage />} />
        <Route path="/GuidedSearch" element={<GuidedSearch />} /> 
        // Add more routes as needed
      </Routes>    
  );
};

export default AppRoutes;