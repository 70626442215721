// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <Container fluid className="bg-light mt-5">
      <Row>
        <Col className="text-center py-3">
          2024 -  {new Date().getFullYear()} The Ultimate Surfboards Comparator©
        </Col>
        <Col className="text-center py-3">
          <Link to="/main/disclaimer">
            Disclaimer
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="text-center py-3"><Link to="/search?brand=Firewire">See all Firewire surfboard and compare them</Link> </Col>
        <Col className="text-center py-3"><Link  to="/search?brand=JS%20Industries">See all JS Industries surfboard and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="/search?brand=Channel%20Island">See all Channel Island surfboard and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="/search?brand=Haydenshapes">See all Hayden Shapes surfboard and compare them</Link></Col>
        </Row>

        <Row>
        <Col className="text-center py-3">        <Link to="/search?size=knee>">See all surfboard for knee high wave and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="/search?size=waist">See all surfboard for waist high wave and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="/search?brand=shoulder">See all surfboard for Shoulder high wave and compare them</Link></Col>
        <Col className="text-center py-3"><Link to="/search?brand=overhead">See all surfboard for overhead high wave and compare them</Link></Col>
               
      </Row>
    </Container>
  );
}

export default Footer;
