import React, { useState, useEffect } from 'react';
import { Container, Button, Accordion, Row, Col, Card, ListGroup } from 'react-bootstrap';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './firebase-config';  // Adjust this path according to your actual config path
//import { CompareSurfboardsProvider } from '../contexts/CompareSurfboardsContext'
//import { useCompareSurfboards } from '../contexts/CompareSurfboardsContext';  // Adjust the path as necessary
import { useCompareSurfboards } from '../contexts/CompareSurfboardsContext';
import { Helmet } from 'react-helmet';

const SurfboardDisplay = () => {
  const { comparedSurfboards } = useCompareSurfboards();
  //const [comparedSurfboards, setComparedSurfboards] = useState([]);
  const [imageIndices, setImageIndices] = useState({});
  const [videoIndices, setVideoIndices] = useState({});
  const [descriptionExpanded, setDescriptionExpanded] = useState(false);
  const [synthesisExpanded, setSynthesisExpanded] = useState(false);

  useEffect(() => {
    // This function initializes indices for each surfboard for displaying images and videos
    const initializeIndices = (surfboards) => {
      const initialImageIndices = {};
      const initialVideoIndices = {};
      surfboards.forEach(surfboard => {
        initialImageIndices[surfboard.id] = 0;
        initialVideoIndices[surfboard.id] = { presentation: 0, review: 0, officialPresentation: 0 };
      });
      setImageIndices(initialImageIndices);
      setVideoIndices(initialVideoIndices);
    };

    if (comparedSurfboards.length > 0) {
      initializeIndices(comparedSurfboards);
    }
  }, [comparedSurfboards]);


  // Toggle functions
  const toggleDescription = () => setDescriptionExpanded(!descriptionExpanded);
  const toggleSynthesis = () => setSynthesisExpanded(!synthesisExpanded);

  // Helper function to format video URLs
  const getVideoSrc = (videoUrl) => {
    if (!videoUrl || typeof videoUrl !== 'string') {
      console.error('Invalid video URL:', videoUrl);
      return ''; // Return a default or error URL if needed
    }
    const youtubeMatch = videoUrl.match(/(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/);
    if (youtubeMatch) return `https://www.youtube.com/embed/${youtubeMatch[1]}`;
    const vimeoMatch = videoUrl.match(/(?:vimeo\.com\/(?!video\/)\d+|player\.vimeo\.com\/video\/(\d+))/);
    if (vimeoMatch) return `https://player.vimeo.com/video/${vimeoMatch[1] || vimeoMatch[2]}`;
    return 'https://www.youtube.com/embed/default'; // default video if URL not matched
  };

  // Image and video navigation helpers
  const nextItem = (currentIndex, length) => (currentIndex + 1) % length;
  const prevItem = (currentIndex, length) => (currentIndex - 1 + length) % length;

  const nextVideo = (type, id) => {
    setVideoIndices(prevIndices => ({
      ...prevIndices,
      [id]: {
        ...prevIndices[id],
        [type]: nextItem(prevIndices[id][type], comparedSurfboards.find(board => board.id === id).mediaLinks[type].length)
      }
    }));
  };

  const prevVideo = (type, id) => {
    setVideoIndices(prevIndices => ({
      ...prevIndices,
      [id]: {
        ...prevIndices[id],
        [type]: prevItem(prevIndices[id][type], comparedSurfboards.find(board => board.id === id).mediaLinks[type].length)
      }
    }));
  };

  const nextImage = (id) => {
    setImageIndices(prevIndices => ({
      ...prevIndices,
      [id]: nextItem(prevIndices[id], comparedSurfboards.find(board => board.id === id).imageUrls.length)
    }));
  };

  const prevImage = (id) => {
    setImageIndices(prevIndices => ({
      ...prevIndices,
      [id]: prevItem(prevIndices[id], comparedSurfboards.find(board => board.id === id).imageUrls.length)
    }));
  };


  /*
  
    return (
      <Container>
        <Row>
          {comparedSurfboards.map(surfboard => (
            <Col key={surfboard.id} xs={12} md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>{surfboard.surfboardName}</Card.Title>
                  <Card.Img variant="top" src={surfboard.imageUrls[imageIndices[surfboard.id]]} />
                  <Card.Text>
                    <ListGroup className="list-group-flush">
                      {surfboard.brandName && <ListGroup.Item>Brand: {surfboard.brandName}</ListGroup.Item>}
                      {surfboard.shaper && <ListGroup.Item>Shaper: {surfboard.shaper}</ListGroup.Item>}
                      {surfboard.firstRelease && <ListGroup.Item>First Release: {surfboard.firstRelease}</ListGroup.Item>}
                    </ListGroup>
                  </Card.Text>
                  <Button variant="secondary" onClick={() => prevImage(surfboard.id)}>Prev Image</Button>
                  <Button variant="secondary" onClick={() => nextImage(surfboard.id)}>Next Image</Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="1" onClick={toggleDescription}>
                    {descriptionExpanded ? 'Hide Description' : 'Show Description'}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    {descriptionExpanded ? comparedSurfboards.map(board => (
                      <Card key={board.id}>
                        <Card.Body>
                          <Card.Title>{board.surfboardName}</Card.Title>
                          <Card.Text>{board.description}</Card.Text>
                        </Card.Body>
                      </Card>
                    )) : null}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Card.Header>
                  <Accordion.Toggle as={Button} variant="link" eventKey="2" onClick={toggleSynthesis}>
                    {synthesisExpanded ? 'Hide Synthesis' : 'Show Synthesis'}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    {synthesisExpanded ? comparedSurfboards.map(board => (
                      <Card key={board.id}>
                        <Card.Body>
                          <Card.Title>{board.surfboardName}</Card.Title>
                          <Card.Text>{board.synthesis}</Card.Text>
                        </Card.Body>
                      </Card>
                    )) : null}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </Container>
    );
  };
  
  */

  return (
    <Container>
      <Helmet>
        <title>Compare Surfboards - Review and Specs</title>
        <meta name="description" content="Compare top surfboards with detailed specs, reviews, videos and ratings. Find the best surfboard for you and your surfing style." />
        <meta name="keywords" content="surfboard finder, surfboard guide, find the right surfboard, surfboard recommendations, beginner surfboards, intermediate surfboards, advanced surfboards, wave size guide, surfboard brands, surfboard selection, surfing tips" />       
      </Helmet>
      <Row>
        {comparedSurfboards.map(surfboard => (
          <Col key={surfboard.id} xs={12} md={6}>            
            <h2>{surfboard.surfboardName}</h2>
            {surfboard.imageUrls && surfboard.imageUrls.length > 0 && (
              <div style={{ height: "500px" }} className="d-flex justify-content-between align-items-center my-2">
                <Button variant="secondary" onClick={() => prevImage(surfboard.id)}>Previous</Button>
                <img
                  style={{ maxWidth: "75%", maxHeight: "475px", objectFit: "contain" }}
                  src={surfboard.imageUrls[imageIndices[surfboard.id]]}
                  alt={`Surfboard ${surfboard.surfboardName}`}
                />
                <Button variant="secondary" onClick={() => nextImage(surfboard.id)}>Next</Button>
              </div>
            )}
            <ListGroup variant="flush">
              {surfboard.officialPage && (
                <ListGroup.Item>
                  <b>Official Page</b>:{" "}
                  <a href={surfboard.officialPage} className="more-info-link">
                    {surfboard.surfboardName}
                  </a>
                </ListGroup.Item>
              )}
              {surfboard.brandName && (
                <ListGroup.Item><b>Brand</b>: {surfboard.brandName}</ListGroup.Item>
              )}
              {surfboard.shaper && (
                <ListGroup.Item><b>Shaper</b>: {surfboard.shaper}</ListGroup.Item>
              )}
              {surfboard.firstRelease && (
                <ListGroup.Item><b>First Release</b>: {surfboard.firstRelease}</ListGroup.Item>
              )}
              {surfboard.surfboardType && surfboard.surfboardType.name && (
                <ListGroup.Item><b>Category</b>: {surfboard.surfboardType.name}</ListGroup.Item>
              )}
              {surfboard.waveSizeRange && (
                <ListGroup.Item><b>Wave size recommended</b>: {surfboard.waveSizeRange.min} to {surfboard.waveSizeRange.max} feet</ListGroup.Item>
              )}
              {surfboard.waveType && surfboard.waveType.name && (
                <ListGroup.Item><b>Wave quality recommended</b>: {surfboard.waveType.name}</ListGroup.Item>
              )}
              {surfboard.surferTypes && surfboard.surferTypes.name && (
                <ListGroup.Item><b>Surfer Type</b>: {surfboard.surferTypes.name}</ListGroup.Item>
              )}
              {surfboard.technologyDescription && (
                <ListGroup.Item><b>Technology</b>: {surfboard.technologyDescription}</ListGroup.Item>
              )}
            </ListGroup>

            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordion-header">Description</Accordion.Header>
                <Accordion.Body style={{ whiteSpace: 'pre-line' }} className="accordion-body">
                  {descriptionExpanded ? surfboard.surfboardDescription :
                    `${surfboard.surfboardDescription.substring(0, 250)}${surfboard.surfboardDescription.length > 250 ? '...' : ''}`}
                  {surfboard.surfboardDescription.length > 250 && (
                    <Button variant="link" onClick={toggleDescription}>
                      {descriptionExpanded ? 'Read Less' : 'Read More'}
                    </Button>
                  )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header className="accordion-header">Synthesis of Review</Accordion.Header>
                <Accordion.Body className="accordion-body">
                  {synthesisExpanded ? surfboard.surfboardReviewSynthesis :
                    `${surfboard.surfboardReviewSynthesis.substring(0, 250)}${surfboard.surfboardReviewSynthesis.length > 250 ? '...' : ''}`}
                  {surfboard.surfboardReviewSynthesis.length > 250 && (
                    <Button variant="link" onClick={toggleSynthesis}>
                      {synthesisExpanded ? 'Read Less' : 'Read More'}
                    </Button>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            <h2>The Official Videos</h2>
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="d-flex flex-column align-items-center my-2">
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <Button variant="secondary" onClick={() => prevVideo('officialPresentation', surfboard.id)}>Previous</Button>
                      <div className="review-counter">
                        {surfboard.mediaLinks.officialPresentation.length > 0 ? (
                          videoIndices[surfboard.id] && videoIndices[surfboard.id].officialPresentation !== undefined ? (
                            `Video ${videoIndices[surfboard.id].officialPresentation + 1} of ${surfboard.mediaLinks.officialPresentation.length}`
                          ) : (
                            <span>Loading...</span>
                          )
                        ) : (
                          "No videos available"
                        )}
                      </div>
                      <Button variant="secondary" onClick={() => nextVideo('officialPresentation', surfboard.id)}>Next</Button>
                    </div>

                    {surfboard.mediaLinks.officialPresentation.length > 0 ? (
                      <div className="review-content w-100 mt-2">
                        <iframe
                          width="100%"
                          height="315"
                          src={getVideoSrc(surfboard.mediaLinks.officialPresentation[videoIndices[surfboard.id]?.officialPresentation]?.url)}
                          title="Video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        ></iframe>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: '315px', width: '100%', backgroundColor: '#f0f0f0', marginTop:'10px' }}>
                        <span>No video yet</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>


            {Array.isArray(surfboard.mediaLinks.review) && surfboard.mediaLinks.review.length > 0 ? (
              <div>
                <h2>All the Reviews</h2>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col">
                      <div className="d-flex flex-column align-items-center my-2">
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <Button variant="secondary" onClick={() => prevVideo('review', surfboard.id)}>Previous</Button>
                          <div className="review-counter">
                            Review {videoIndices[surfboard.id].review + 1} of {surfboard.mediaLinks.review.length}
                          </div>
                          <Button variant="secondary" onClick={() => nextVideo('review', surfboard.id)}>Next</Button>
                        </div>
                        <div className="review-content w-100 mt-2">
                          <iframe
                            width="100%"
                            height="315"
                            src={getVideoSrc(surfboard.mediaLinks.review[videoIndices[surfboard.id]?.review]?.url)}
                            title="Video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="my-2">
                <h2>All the Reviews</h2>
                <div className="d-flex justify-content-center align-items-center" style={{ height: '315px', width: '100%', backgroundColor: '#f0f0f0' }}>
                  <span>No video yet</span>
                </div>
              </div>
            )}

          </Col>
        ))}
      </Row>      
    </Container>
  );
};

export default SurfboardDisplay;
